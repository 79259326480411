import { Observable } from "rxjs";
import { IFarmService } from "./farm.service.interface";
import { IFarmRepository } from "src/app/domain/repositories/farm-repository.interface";
import { Farm } from "src/app/domain/models/farm";
import { Injectable } from "@angular/core";
import { TurbineDetail } from "src/app/domain/models/turbine-detail";

@Injectable({
    providedIn: 'root'
  })

export class FarmService implements IFarmService {
    
    constructor(
        private farmRepository: IFarmRepository
    ){ }
    
    getTurbines(farmId: string): Observable<TurbineDetail[]> {
        return this.farmRepository.getTurbines(farmId);
    }
    getAllFarms(): Observable<Farm[]> {
        return this.farmRepository.getAll();
    }
    getFarmDetail(farmId: string): Observable<Farm> {
        return this.farmRepository.getFarmDetail(farmId)
    }
    findFarmsByName(name: string): Observable<Farm[]> {
        return this.farmRepository.findFarmsByName(name);
    }
}